import * as React from "react";
import { I_COPY_BOX_PROPS } from "../../02_Interfaces/I_COPY_BOX";
import { ENU_ICON_TYPE, ENU_SHARE_TYPE } from "../../02_Interfaces/IGLOBAL";
import { APP_BUTTON } from "../Components/BUTTON";
import { GetIconFromType } from "../../04_TOOLS/tools";
import { GROUP_BOX_ITEM } from "../Components/APP_NEW_01/GROUP_BOX_ITEM";
import { I_GROUPE_NOTE_ITEM } from "../../02_Interfaces/INOTE";
import { GROUP_BOX_ITEM_NEW } from "../Components/APP_NEW_01/GROUP_BOX_ITEM_NEW";

export class COPY_BOX extends React.Component<I_COPY_BOX_PROPS, {}>{

    render(): JSX.Element {
        return <div>
            {/* font blanc non opaque  */}
            <div className="POPUP_LAYER" style={{ opacity: 0.4 }}></div>

            <div className="POPUP_BODY_BOX" style={{
                top: "20%",
                bottom: "20%",
                left: "10%",
                width: "80%"
            }}>
                {/* Titre */}
                <div className="POPUP_TITRE">
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.copy, 24) }}>

                    </span>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <span>Copie de note</span>
                </div>

                <div className="POPUP_BODY" style={{ textAlign: "center" }}>
                    {
                        this.props.APP_GLOBAL_DATA.DB_GROUPS !== undefined &&
                        this.props.APP_GLOBAL_DATA.DB_GROUPS
                            .filter(g =>
                                this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                g.id !== this.props.APP_GLOBAL_DATA.selectedGroup.id &&
                                g.isAuthor
                            )
                            .map(g => {
                                return <GROUP_BOX_ITEM_NEW key={g.id} GLOBAL_DATA={this.props.APP_GLOBAL_DATA} GLOBAL_FUNCTION={this.props.APP_GLOBAL_FUNCTION} groupItem={g} />
                            })
                    }
                </div>

                {/* Zone d'actions profil */}
                {
                    <div className="POPUP_ACTION_ZONE">


                        {
                            this.props.APP_GLOBAL_DATA.DB_GROUPS !== undefined &&
                            this.props.APP_GLOBAL_DATA.DB_GROUPS.filter(g => this.props.APP_GLOBAL_DATA.selectedGroup !== undefined && g.selected !== undefined && g.selected && g.id !== this.props.APP_GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0 &&
                            <APP_BUTTON buttonProps={
                                {
                                    title: "Copier",
                                    F_BUTTON_ACTION: () => {
                                        (this.props.APP_GLOBAL_FUNCTION.onCopyNoteToGroup as Function)()
                                    },
                                }
                            } />
                        }

                        <APP_BUTTON buttonProps={
                            {
                                title: "Dupliquer dans le carnet '" + (this.props.APP_GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).title + "'",
                                F_BUTTON_ACTION: () => {
                                    (this.props.APP_GLOBAL_FUNCTION.onCopyNoteToGroup as Function)(true)
                                },
                            }
                        } />


                        <APP_BUTTON buttonProps={
                            {
                                title: "Fermer",
                                F_BUTTON_ACTION: () => {
                                    (this.props.APP_GLOBAL_FUNCTION.openCopyNoteMode as Function)()
                                },
                            }
                        } />

                    </div>
                }
            </div>
        </div>
    }
}