import * as React from "react";
import { CupBoardCode, GetDistinctListOfString, GetIconFromType, GoToElement, IsComptaForm, IsCustForm, IsTypeNoteIsAnCupBord } from "../../04_TOOLS/tools";
import { ENU_ICON_TYPE, I_NAVIGATION_ITEM } from "../../02_Interfaces/IGLOBAL";
import { I_CUPBOARD_PROPS } from "../../02_Interfaces/I_CUPBOARD";
import { CONTEXTUEAL_MENU_ITEM } from "./CONTEXTUAL_MENU";
import "../../99_Styles/cupboard.css";
import { ENU_EYE_STATUS } from "../../02_Interfaces/I_EYE";
import { EYE_DYN } from "./EYE_DYN";
import { SEARCHBOX } from "./SEARCHBOX";
import { I_NOTE_ITEM } from "../../02_Interfaces/INOTE";


export class CUPBOARD extends React.Component<I_CUPBOARD_PROPS, {}> {

    private ref_SearchBox = React.createRef<SEARCHBOX>();
    private ref_search_task_onCupboard = React.createRef<SEARCHBOX>();


    getDistinctTags = (tags: string[][]) => {

        let tmptags: string[] = [];

        for (let x = 0; x < tags.length; x++) {
            for (let y = 0; y < tags[x].length; y++) {
                if (tmptags.filter(t => t === (tags[x][y]) && t.length > 0).length === 0) {
                    tmptags.push((tags[x][y]) as string);
                }
            }
        }


        return GetDistinctListOfString(tmptags);
    }

    componentDidUpdate(prevProps: Readonly<I_CUPBOARD_PROPS>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.ref_SearchBox.current !== null && this.props.currentNote.searInChildFromCubBoard !== undefined &&
            this.props.currentNote.searInChildFromCubBoard !== this.ref_SearchBox.current.state.searchValue) {
            this.ref_SearchBox.current.forceUpdateValue(this.props.currentNote.searInChildFromCubBoard)
        }
    }




    render(): JSX.Element {
        return <div className="CUPBOARD_BOX" title={this.props.currentNote.showChilFromCupBoard ? "Cliquez dans la zone du titre du classeur pour fermer le classeur" : "Cliquez dans la zone du titre du classeur pour ouvrir le classeur"}>
            <div className="CUPBOARD_BANNER_ZONE" onClick={() => {
                (this.props.GLOBAL_FUNCTION.ShowCupBoardChild as Function)(this.props.currentNote.idnote)
            }}>
                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupboardContent, 22) }}></span>
                <span className="SPAN_SEP">
                </span>
                <span >
                    <span>Contenu du classeur</span>
                    &nbsp;
                    <span className="CUPBOARD_TITLE">{
                        IsCustForm(this.props.currentNote.title) ?
                            this.props.currentNote.title.split("]-")[1] :
                            this.props.currentNote.title
                    }</span>

                    <span className="SPAN_SEP"></span>
                    {
                        //Si  non classeur
                        //-----------------
                        <span >
                            <span>
                                <EYE_DYN
                                    allwaysOnTop={false}
                                    eye_status={
                                        this.props.currentNote.showChilFromCupBoard ? ENU_EYE_STATUS.open : ENU_EYE_STATUS.close
                                    }
                                />
                            </span>
                        </span>
                    }
                    <span style={{ fontSize: "11px" }}>
                        {
                            this.props.GLOBAL_DATA.DB_TASKS !== undefined && this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.currentNote.idnote).length > 0 &&
                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.currentNote.idnote).filter(t => t.taskdate !== null && t.taskdate.length > 0 && !t.finished).length > 0 &&
                            <span>
                                <span className="SPAN_SEP"></span>
                                <span>
                                    {
                                        this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.currentNote.idnote).filter(t => (t.taskdate === null || (t.taskdate !== null && t.taskdate.length > 0)) && !t.finished).length
                                    }
                                </span>
                                <span>-</span>
                                <span style={{ color: "red" }}>{
                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.currentNote.idnote).filter(t => t.taskdate !== null && t.taskdate.length > 0 && !t.finished).length
                                }</span>
                            </span>
                        }
                    </span>

                </span>
                {/* <span style={{ display: "block", backgroundColor: "#ffffff", borderRadius: "4px 1px 1px 1px" }} >
                    {
                        this.props.currentNote.tags.split(";").filter(t => t.length > 0).map(t => {
                            return <span key={t} className="HOME_STATE_TAGS_ZONE">
                                {
                                    t
                                }
                            </span>
                        })
                    }
                </span> */}

            </div>

            <div className="CUPBOARD_CHILD_DETAIL_BOX">

                <div className={this.props.currentNote.showChilFromCupBoard ? "CUBBOARD_ACTION_ZONE CUBBOARD_ACTION_ZONE_SELECTED" : "CUBBOARD_ACTION_ZONE"} >
                    <CONTEXTUEAL_MENU_ITEM menus={[

                        {
                            icon: GetIconFromType(ENU_ICON_TYPE.markBook, 16),
                            description: "Mettre le classeur en favoris",
                            F_Menu_Action: () => {
                                (this.props.GLOBAL_FUNCTION.piningNote as Function)(this.props.currentNote.idnote, !this.props.currentNote.pined)
                            },
                            subMenu: false,
                        },
                        {
                            icon: GetIconFromType(ENU_ICON_TYPE.PutInFront, 16),
                            description: "Mise en évidence de la note",
                            F_Menu_Action: () => {
                                (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.currentNote)
                            },
                            subMenu: false
                        },
                        {
                            icon: GetIconFromType(ENU_ICON_TYPE.saveAsLocal, 16),
                            description: "Sauvegarder temporairement",
                            F_Menu_Action: () => {
                                (this.props.GLOBAL_FUNCTION.onSaveLocalMemory as Function)(this.props.currentNote)
                            },
                            subMenu: false
                        },
                        {
                            icon: GetIconFromType(ENU_ICON_TYPE.newItem, 16),
                            description: "Ajouter une nouvelle note au classeur",
                            F_Menu_Action: () => {
                                (this.props.GLOBAL_FUNCTION.addNewNoteInGroup as Function)(undefined, this.props.currentNote)
                            },
                            subMenu: false
                        }
                    ]} />
                </div>

                {
                    (this.props.GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[])
                        .filter(_n => _n.canBeShown !== undefined && this.props.currentNote.canBeShown && this.props.currentNote.associations !== null &&
                            this.props.currentNote.associations.split(";").filter(a => parseInt(a) === _n.idnote).length > 0
                        ).length > 0 &&
                    <div>
                        <div>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.note, 12) }}></span>
                            <span className="SPAN_SEP"></span>
                            <span>
                                {
                                    (this.props.GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[])
                                        .filter(_n => _n.canBeShown !== undefined && this.props.currentNote.canBeShown && this.props.currentNote.associations !== null &&
                                            this.props.currentNote.associations.split(";").filter(a => parseInt(a) === _n.idnote).length > 0
                                        ).length
                                }
                            </span>
                        </div>
                    </div>
                }

                <div >
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 12) }}></span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            {
                                this.props.currentNote.associations !== null &&
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                GetDistinctListOfString(
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                                        this.props.currentNote.associations.split(";").filter(a => parseInt(a) === n.idnote &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(t => t.id === n.type).length > 0 &&
                                            IsTypeNoteIsAnCupBord(
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(t => t.id === n.type)[0].title
                                            )
                                        ).length > 0)
                                        .map(n => { return n.type.toString() })).length
                            }
                        </span>
                        <span>:</span>
                        <span>
                            {
                                this.props.currentNote.associations !== null &&
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&

                                GetDistinctListOfString(
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                                        this.props.currentNote.associations.split(";").filter(a => parseInt(a) === n.idnote &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(t => t.id === n.type).length > 0 &&
                                            IsTypeNoteIsAnCupBord(
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(t => t.id === n.type)[0].title
                                            )
                                        ).length > 0)
                                        .map(n => { return n.type.toString() }))
                                    .map(t => { return parseInt(t) }).map(t => {
                                        return <span key={t} className={this.props.currentNote.selectedTypeNote !== undefined && this.props.currentNote.selectedTypeNote.filter(_t => _t === t).length > 0 ? "HOME_STATE_TYPE_NOTE CUBBOARD_TYPE_SELECTED" : "HOME_STATE_TYPE_NOTE"}
                                            onClick={() => {
                                                (this.props.GLOBAL_FUNCTION.SelectTypeNoteInCubBoard as Function)(this.props.currentNote.idnote, t)
                                            }}
                                            style={{
                                                marginLeft: "3px", marginRight: "3px", fontSize: "10px", /*border: "2px solid #" + this.props.GLOBAL_DATA.DB_TYPES_NOTES?.filter(tn => tn.id === t)[0].colorString*/
                                            }} >
                                            <span className="TEXT_TICKET" style={{ textAlign: "center" }}>
                                                <span className="CUPBOARD_ANIMATION_BOX">
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 24, this.props.GLOBAL_DATA.DB_TYPES_NOTES?.filter(tn => tn.id === t)[0].colorString) }}>

                                                    </span>

                                                </span>
                                                <div>
                                                    {
                                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES?.filter(tn => tn.id === t)[0].title.replace(CupBoardCode, "")
                                                    }
                                                </div>
                                            </span>
                                        </span>
                                    })

                            }
                        </span>
                        {
                            this.props.currentNote.selectedTypeNote !== undefined &&
                            this.props.currentNote.selectedTypeNote.filter(tn =>
                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_t => _t.id === tn && IsTypeNoteIsAnCupBord(_t.title)).length > 0
                            ).length > 0 &&
                            <span onClick={() => {
                                (this.props.GLOBAL_FUNCTION.SelectTypeNoteInCubBoard as Function)(this.props.currentNote.idnote)
                            }}>
                                <span className="SPAN_SEP">
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.clearFilter, 12, "d61919") }}>

                                </span>
                            </span>
                        }
                    </div>
                </div>


                <div >
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.category, 12) }}></span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            {
                                this.props.currentNote.associations !== null &&
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                GetDistinctListOfString(
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                                        this.props.currentNote.associations.split(";").filter(a => parseInt(a) === n.idnote &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(t => t.id === n.type).length > 0 &&
                                            !IsTypeNoteIsAnCupBord(
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(t => t.id === n.type)[0].title
                                            )
                                        ).length > 0)
                                        .map(n => { return n.type.toString() })).length
                            }
                        </span>
                        <span>:</span>
                        <span>
                            {
                                this.props.currentNote.associations !== null &&
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&

                                GetDistinctListOfString(
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                                        this.props.currentNote.associations.split(";").filter(a => parseInt(a) === n.idnote &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(t => t.id === n.type).length > 0 &&
                                            !IsTypeNoteIsAnCupBord(
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(t => t.id === n.type)[0].title
                                            )
                                        ).length > 0)
                                        .map(n => { return n.type.toString() }))
                                    .map(t => { return parseInt(t) }).map(t => {
                                        return <span key={t} className={this.props.currentNote.selectedTypeNote !== undefined && this.props.currentNote.selectedTypeNote.filter(_t => _t === t).length > 0 ? "HOME_STATE_TYPE_NOTE CUBBOARD_TYPE_SELECTED" : "HOME_STATE_TYPE_NOTE"}
                                            onClick={() => {
                                                (this.props.GLOBAL_FUNCTION.SelectTypeNoteInCubBoard as Function)(this.props.currentNote.idnote, t)
                                            }}
                                            style={{
                                                marginLeft: "3px", marginRight: "3px", fontSize: "10px", /*border: "2px solid #" + this.props.GLOBAL_DATA.DB_TYPES_NOTES?.filter(tn => tn.id === t)[0].colorString*/
                                            }}>
                                            <span className="TEXT_TICKET" style={{ textAlign: "center" }}>
                                                <span className="CUPBOARD_ANIMATION_BOX">
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.category, 24, this.props.GLOBAL_DATA.DB_TYPES_NOTES?.filter(tn => tn.id === t)[0].colorString) }}>

                                                    </span>

                                                </span>
                                                <div>
                                                    {
                                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES?.filter(tn => tn.id === t)[0].title
                                                    }
                                                </div>
                                            </span>
                                        </span>
                                    })

                            }
                        </span>
                        {
                            this.props.currentNote.selectedTypeNote !== undefined &&
                            this.props.currentNote.selectedTypeNote.filter(tn =>
                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_t => _t.id === tn && !IsTypeNoteIsAnCupBord(_t.title)).length > 0
                            ).length > 0 &&
                            <span onClick={() => {
                                (this.props.GLOBAL_FUNCTION.SelectTypeNoteInCubBoard as Function)(this.props.currentNote.idnote)
                            }}>
                                <span className="SPAN_SEP">
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.clearFilter, 12, "d61919") }}>

                                </span>
                            </span>
                        }
                    </div>
                </div>



                <div>
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.tag, 12) }}></span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            {
                                this.props.currentNote.associations !== null &&
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.getDistinctTags
                                    (this.props.GLOBAL_DATA.DB_NOTES
                                        .filter(n => this.props.currentNote.associations !== null && this.props.currentNote.associations
                                            .split(";")
                                            .filter(a => a.length > 0 && parseInt(a) === n.idnote).length > 0)
                                        .map(n => { return n.tags.split(";") })).length

                            }
                        </span>
                        <span>:</span>
                        <span>
                            {
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.getDistinctTags
                                    (this.props.GLOBAL_DATA.DB_NOTES
                                        .filter(n => this.props.currentNote.associations !== null && this.props.currentNote.associations
                                            .split(";")
                                            .filter(a => a.length > 0 && parseInt(a) === n.idnote).length > 0)
                                        .map(n => { return n.tags.split(";") }))
                                    .sort((a, b) => { return a.localeCompare(b) })
                                    .map(t => {
                                        return <span key={t} onClick={() => {
                                            (this.props.GLOBAL_FUNCTION.SelectTagNoteInCubBoard as Function)(this.props.currentNote.idnote, t)
                                        }}>
                                            <span className={this.props.currentNote.selectedTags !== undefined && this.props.currentNote.selectedTags.filter(_t => _t === t).length > 0 ? "HOME_STATE_TAGS_ZONE CUPBOARD_TAG_SELECTED" : "HOME_STATE_TAGS_ZONE"}>{t}</span>
                                        </span>
                                    })
                            }
                        </span>

                        {
                            this.props.currentNote.selectedTags !== undefined && this.props.currentNote.selectedTags.length > 0 &&
                            <span onClick={() => {
                                (this.props.GLOBAL_FUNCTION.SelectTagNoteInCubBoard as Function)(this.props.currentNote.idnote)
                            }}>
                                <span className="SPAN_SEP">
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.clearFilter, 12, "d61919") }}>

                                </span>
                            </span>
                        }

                    </div>
                </div>

                {
                    (
                        this.props.currentNote.showChilFromCupBoard &&
                        this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                        (this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                            /*!t.finished && t.taskdate.length > 0 &&*/
                            this.props.currentNote.associations !== null &&
                            this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                        ).length > 0)
                    ) &&
                    <div className="CUPBOARD_TASK_BOX_ZONE">
                        <div className="CUPBOARD_TASK_BOX_TITLE">
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.pendingtasks, 18) }}></span>
                            &nbsp;&nbsp;
                            <span>Taches enfants de premier niveau de profondeur</span>
                            <div style={{ textAlign: "center" }}>
                                <SEARCHBOX ref={this.ref_search_task_onCupboard} F_UPDATE_SEARCHVALUE={() => { }}
                                    placeHolder="Rechercher une tache" forceUpdateEveryTime={false}
                                    APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                            </div>
                        </div>
                        <div>

                            {
                                //Les taches non termiée et datées dans le classeur
                                //--------------------------------------------------
                                this.props.currentNote.showChilFromCupBoard &&
                                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                    /*!t.finished && t.taskdate.length > 0 &&*/
                                    this.props.currentNote.associations !== null &&
                                    this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                                ).length > 0 &&
                                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                    /*!t.finished && t.taskdate.length > 0 &&*/
                                    this.props.currentNote.associations !== null &&
                                    this.props.currentNote.associations.split(";").filter(a => a !== "NaN" && a.length > 0 && parseInt(a) === t.idnote).length > 0
                                )
                                    .filter(t =>
                                        this.ref_search_task_onCupboard.current !== null &&
                                            this.ref_search_task_onCupboard.current.state.searchValue.length > 0 ?
                                            (
                                                t.title.toUpperCase().indexOf(this.ref_search_task_onCupboard.current.state.searchValue.toUpperCase()) > -1 ||
                                                (
                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => t.idnote === n.idnote).length > 0 &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => t.idnote === n.idnote)[0].title.toUpperCase().indexOf(this.ref_search_task_onCupboard.current.state.searchValue.toUpperCase()) > -1
                                                )
                                            ) : true)
                                    .sort((a, b) => { return b.idnote - a.idnote })
                                    .map(t => {
                                        return <div className="CUPBOARD_TASKS_LINE">

                                            <span onClick={() => {
                                                //Modification du statut de la tache de l'enfant
                                                (this.props.GLOBAL_FUNCTION.checkTaskNote as Function)(t.idtask, !t.finished)
                                            }} style={{
                                                backgroundColor:
                                                    "#" + (this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined ?
                                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote &&
                                                                n.type === tn.id
                                                            ).length > 0
                                                        )[0].colorString as string : "grey"),
                                                padding: "2px",
                                                margin: "4px",
                                                borderRadius: "7px",
                                                display: "inline-block"
                                            }}>
                                                <span className="TEXT_TICKET" >
                                                    {
                                                        t.taskdate.length > 0 &&
                                                        <span dangerouslySetInnerHTML={{__html:GetIconFromType(ENU_ICON_TYPE.pendingtasks,11)}}>
                                                        </span>
                                                    }
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(t.finished ? ENU_ICON_TYPE.checkedTask : ENU_ICON_TYPE.unCheckedTask, 12) }}></span>
                                                    &nbsp;
                                                    &nbsp;
                                                    <span style={{ textDecoration: t.finished ? "line-through" : undefined, fontSize: "12px" }}>{
                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                        (IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title) && t.title.indexOf("]:") > -1 ?
                                                            t.title.split("]:")[1] :
                                                            t.title)
                                                    }</span>

                                                    {
                                                        t.taskdate.length > 0 &&
                                                        <span>
                                                            &nbsp;
                                                            <span>
                                                                ({
                                                                    t.taskdate
                                                                })
                                                            </span>
                                                        </span>
                                                    }

                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    <span className="CUBBOARD_TASK_NOTE_INFO_BOX">
                                                        <span>
                                                            {
                                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                                (IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title) ?
                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title.split("]-")[1] :
                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title)
                                                            }
                                                        </span>
                                                        &nbsp;
                                                        &nbsp;
                                                        &nbsp;
                                                        <span className="APP_NOTE_TYPE_INFO_TITLE">
                                                            {
                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                (IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote &&
                                                                        n.type === tn.id
                                                                    ).length > 0
                                                                )[0].title) ?
                                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote &&
                                                                            n.type === tn.id
                                                                        ).length > 0
                                                                    )[0].title.replace(CupBoardCode, "")
                                                                    :
                                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote &&
                                                                            n.type === tn.id
                                                                        ).length > 0
                                                                    )[0].title)
                                                            }
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                            <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {

                                                (this.props.GLOBAL_FUNCTION.SearchNoteInCupBoard as Function)(this.props.currentNote.idnote, "");

                                                (this.props.GLOBAL_FUNCTION.SelectTypeNoteInCubBoard as Function)(this.props.currentNote.idnote);
                                                (this.props.GLOBAL_FUNCTION.SelectTagNoteInCubBoard as Function)(this.props.currentNote.idnote);

                                                (this.props.GLOBAL_FUNCTION.onUpdateNavigationStatuts as Function)({
                                                    noteId: this.props.currentNote.idnote,
                                                    isChildOpened: true,
                                                    isNoteOpened: true
                                                } as I_NAVIGATION_ITEM).then(() => {





                                                    setTimeout(() => {
                                                        //Aller vers la note
                                                        GoToElement("SHORT_ITEM_" + t.idnote);
                                                    }, 500);

                                                })
                                            }}>
                                                Voir la note
                                            </span>
                                        </div>

                                    })
                            }



                        </div>
                    </div>
                }




                <div className="CUPBOARD_SEARCH_ZONE">
                    {
                        this.props.currentNote.showChilFromCupBoard &&
                        <SEARCHBOX ref={this.ref_SearchBox} placeHolder="Recherche dans les notes du classeur ..." forceUpdateEveryTime={true} F_UPDATE_SEARCHVALUE={() => {
                            if (this.ref_SearchBox.current !== null) {
                                (this.props.GLOBAL_FUNCTION.SearchNoteInCupBoard as Function)(this.props.currentNote.idnote, this.ref_SearchBox.current.state.searchValue);
                            }

                        }} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                    }

                    <span>
                        {
                            (this.props.GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[])
                                .filter(_n => _n.canBeShown !== undefined && this.props.currentNote.canBeShown && this.props.currentNote.associations !== null &&
                                    this.props.currentNote.associations.split(";").filter(a => parseInt(a) === _n.idnote).length > 0 &&
                                    (
                                        this.props.currentNote.selectedTypeNote !== undefined &&
                                            this.props.currentNote.selectedTypeNote.length > 0 &&
                                            this.props.currentNote.selectedTypeNote.filter(tn => tn === _n.type).length === 0 ? false : true
                                    )
                                    &&
                                    (
                                        this.props.currentNote.selectedTags !== undefined &&
                                            this.props.currentNote.selectedTags.length > 0 &&
                                            this.props.currentNote.selectedTags.filter(t => t === _n.tags !== null && _n.tags.split(";").filter(_t => _t === t).length > 0).length === 0 ? false : true
                                    )
                                ).length
                        }
                    </span>
                </div>

            </div>
        </div>
    }
}