import { ENU_COLOR_TYPE, ENU_ICON_TYPE, I_APP_GLOBAL_DATA, I_APP_GLOBAL_FUNCTIONS, KeyValuePair } from "./IGLOBAL";

export interface I_GROUP_CATEGORY_ITEM{
    idGroupCategory:number;
    idGroup:number;
    categoryName:string;
}

export interface I_GROUPE_NOTE_ITEM {
    id: number,
    title: string
    memory: string,
    isAuthor: number;
    authorLastname: string;
    authorName: string;
    pined?: boolean;
    selected?:boolean;//présélection du carnet
    //Les states
    //-----------
    nbTaskReminded?:number;
    nbTask?:number;
    nbNotes?:number;
    category?:string;
    color?:string;

    //Sauvegarde des filtres actifs lors de la sortie
    //-----------------------------------------------
    // ls_onNoteIdSelected?:number;
    // ls_selectedNoteMenu?:ENU_ICON_TYPE;
    // ls_selectedTypeMenu?:ENU_TYPE_NOTE;
    // ls_searchNote?:string;
    // ls_selectedTags?:string[];
    // ls_selectedType?:number[];
    // ls_openedNotes?:number[];
    // ls_selectedNoteToLink?:I_NOTE_ITEM;
    //-----------------------------------------------
}


export interface I_NOTE_ITEM {
    idgroupnote: number,
    associations: string,
    idnote: number,
    title: string,
    typeNoteString: string,
    colorString: string,
    color: number,
    type: number,
    tags: string,
    pined: boolean,
    created: string,
    modified: string,
    description: string,
    descriptionraw: any,
    isAuthor: number;
    autor: string;
    editor: string;

    //Commentaires de la note
    noteComments?: I_COMMENT_NOTE_ITEM[];

    //Element de résultat de recherche
    //---------------------------------
    nbTask_t?: number;
    nbTask_s?: number;
    nbTask_unfinished?: number;
    nbAttachment_t?: number;
    nbAttachment_s?: number;
    typeTitle?: string;
    typeColor?: string;
    //---------------------------------

    //Optimisation de l'affichage des notes
    canBeShown?: boolean;
    //---------------------------------

    //Corbeille ou archivage ?
    displayUnity:number;
    //---------------------------------

    //Classeur
    showChilFromCupBoard:boolean;
    searInChildFromCubBoard:string;
    selectedTypeNote:number[];
    selectedTags:string[];
    //--------

    powerValue?:number;
}

export interface I_TYPENOTE {
    id: number;
    idGroupNote: number;
    idnote: number;
    title: string;
    typeNote: ENU_TYPE_NOTE;
    typeNoteString?: string;
    color?: number;//Identifiant de la couleur (n'est plus utilisé);
    colorString?: string;
    isAuthor: number;

    //States
    //----------
    STATS_COUNT_ON_NOTE?: 0 | number;
    //----------

    //Operation
    selected: boolean;
    //--------
}

export interface I_TYPE_NOTE_ACCOUNT_ITEM {
    keyValue: KeyValuePair;
    typeNote: ENU_COLOR_TYPE;
    colorItem: string;
}



//Classes
export interface I_GROUPE_NOTE_ITEM_PROPS {
    groupeItem: I_GROUPE_NOTE_ITEM;
    selected: boolean;

    F_SelectGoupNote: Function;
    F_UpdateGroupNote: Function;
    asChild:boolean;

    APP_GLOBAL_DATA: I_APP_GLOBAL_DATA;
    APP_GLOBAL_FUNCTION: I_APP_GLOBAL_FUNCTIONS;

    
}

export interface I_GROUPE_NOTE_ITEM_PROPS_STATE {
    editMode: boolean;
    value: string;

    //Info async
    nbNotes?: number;
    nbTask?: number;
    nbTaskReminded?: number;
    needUpdate: boolean;
}

export interface I_NOTE_ITEM_PROPS {
    note: I_NOTE_ITEM;
    showTaskNote: boolean;
    attachments?: I_ATTACHMENT_LINK_ITEM[];
    typesNotes: I_TYPENOTE[];
    tasks?: I_TASK_ITEM[];
    APP_GLOBAL_FUNCTION: I_APP_GLOBAL_FUNCTIONS;
    APP_GLOBAL_DATA: I_APP_GLOBAL_DATA;
    asLinkMode: boolean;
    linkedItems?: string[];
    initialLinkedItem?: number;
    tags: string[];
    withEditMenu: boolean;
    notesOnEditMode: number[];
    asChild:boolean;
    currentThread:number;
    parentNoteItem?:I_NOTE_ITEM;
}

export interface I_NOTE_ITEM_PROPS_STATE {
    editMode: boolean;
    titleValue: string;
    selectedTask?: I_TASK_ITEM;
    open: boolean;
    onLoad: boolean;
    cash_Tasks: I_TASK_ITEM[];
    tagTmpValue: string;
    selectedTagIndex: number;
    currentEditor: string;
    comentMode: boolean;
    coments: I_COMMENT_NOTE_ITEM[];
    canBeShown: boolean;
    showRelatedNote:boolean;
    currentSugestion:I_NOTE_ITEM[];
    sugestionMaxValue:number;
}

export interface I_ATTACHMENT_LINK_ITEM {
    id: number;
    nodeId: number;
    title: string;
    body?: string;
}

export interface I_TASK_ITEM {
    idtask: number,
    title: string,
    finished: boolean,
    idnote: number,
    taskdate: string,
    taskNoteTitle?: string,
    taskGroupId?: number
}

export interface I_COMMENT_NOTE_ITEM {
    idComent: number;
    idNote: number;
    idUP: number;
    // idUP: I_USER_PROFIL_ITEM;//Profil de l'utilisateur
    author: string;
    coment: string;
    created: string;
    modified?: string;
}

export interface I_FAVORIT_ITEM {
    idPinedGroup: number;
    idGroup: number;
}

export interface I_NOTE_OVER_GROUP_ITEM{
    idLinkedItem:number,
    idGroup:number,
    idNote:number,
    idOtherNote:number,
    idOtherGroup:number,
    noteItem?:I_NOTE_ITEM,
    isOpen?:boolean;
}


//Enums
export enum ENU_TYPE_NOTE {
    task, note, reminder
}
