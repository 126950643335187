import * as React from "react";
import { I_NOTE_VIEW_SYNTHESE_PROPS, I_NOTE_VIEW_SYNTHESE_PROPS_STATE } from "../../../02_Interfaces/I_NOTE_VIEW_SYNTHSE";
import { CupBoardCode, CustFormCode, GetDateObjectFromString, GetDistinctListOfString, GetIconFromType, GetNoteCustFormData, GetNoteCustParentIdFromTitle, GoToElement, IsCustForm, IsTypeNoteIsAnCupBord } from "../../../04_TOOLS/tools";
import { ENU_ACTION_TYPE, ENU_ICON_TYPE, I_KEY_VALUE_DATA } from "../../../02_Interfaces/IGLOBAL";
import { DB_DAY_NAME, DB_MONTH_NAME, NOTE_CUST_FORM_EMPTY_PARENT, NOTE_CUST_FORM_EMPTY_TITLE } from "../../../01_APPDATA/DB_APP";
import { SEARCHBOX } from "../SEARCHBOX";
import { Guid } from "guid-typescript";
import { I_MSG_OPTION } from "../../../02_Interfaces/IMSGBOX";


export class NOTE_VIEW_SYNTHESE extends React.Component<I_NOTE_VIEW_SYNTHESE_PROPS, I_NOTE_VIEW_SYNTHESE_PROPS_STATE> {

    constructor(props: I_NOTE_VIEW_SYNTHESE_PROPS) {
        super(props);

        this.state = {
            showAllSynthese: false,
            menuSynthese: [
                {
                    key: Guid.create().toString(),
                    value: "Org. / Classeurs",
                    data: ENU_ICON_TYPE.cupBoard
                },
                {
                    key: Guid.create().toString(),
                    value: "Org. / Intercalaires",
                    data: ENU_ICON_TYPE.category
                },
                {
                    key: Guid.create().toString(),
                    value: "Navigation",
                    data: ENU_ICON_TYPE.path
                },
                {
                    key: Guid.create().toString(),
                    value: "Taches du carnets",
                    data: ENU_ICON_TYPE.pendingtasks
                },
            ],
        }

        this.showAllSynthse = this.showAllSynthse.bind(this);
        this.onSelectSubMenu = this.onSelectSubMenu.bind(this);
    }

    showAllSynthse = () => {
        this.setState({ showAllSynthese: !this.state.showAllSynthese });
    }

    onSelectSubMenu = (subMenu: I_KEY_VALUE_DATA) => {
        this.setState({ selectedMenu: subMenu }, () => {
            (this.props.GLOBAL_FUNCTION.OnChangeNoteViewSyntheseMenu as Function)(subMenu.data as ENU_ICON_TYPE)
        })
    }

    componentDidMount(): void {
        if (this.state.menuSynthese.filter(m => m.data as ENU_ICON_TYPE === this.props.GLOBAL_DATA.selectedsyntheseMenu).length > 0)
            this.onSelectSubMenu(this.state.menuSynthese.filter(m => m.data as ENU_ICON_TYPE === this.props.GLOBAL_DATA.selectedsyntheseMenu)[0]);
    }

    render(): JSX.Element {
        try {
            return <div className="NOTE_VIEW_SYNTHSE_BOX">
                <div style={{ textAlign: "left", paddingLeft: "7px" }}>
                    {
                        this.state.menuSynthese.map(m => {
                            return <div key={m.key}
                                onClick={() => {
                                    this.onSelectSubMenu(m)
                                }}
                                className={this.state.selectedMenu !== undefined && this.state.selectedMenu.key === m.key ? "MENU_SYNTHESE_ITEM_BOX MENU_SYNTHESE_ITEM_BOX_SELECTED" : "MENU_SYNTHESE_ITEM_BOX"}>




                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(m.data as ENU_ICON_TYPE, 11, "red!important") }}>

                                </span>
                                &nbsp;


                                <span>{
                                    m.value as string
                                }</span>

                                {
                                    (m.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.pendingtasks &&
                                    <span>
                                        &nbsp;
                                        {
                                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && t.taskdate.length > 0).length > 0 &&
                                            <span className="FOLDER_TASK_COUNT_REMEMMING">
                                                {
                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && t.taskdate.length > 0).length
                                                }
                                            </span>
                                        }
                                    </span>
                                }
                            </div>
                        })
                    }
                </div>
                <div className="NOTE_VIEW_SYNTHESE_RECAP_ZONE">
                    <div className="TEXT_TICKET">

                        {
                            this.state.selectedMenu !== undefined &&
                            (this.state.selectedMenu.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.path &&
                            <div style={{ position: "relative", display: "block", overflowX: "auto", overflowY: "hidden" }}>
                                {
                                    this.props.GLOBAL_DATA.allPath.filter(p =>
                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                        p.groupeId === this.props.GLOBAL_DATA.selectedGroup.id &&
                                        (
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId).length > 0
                                        )
                                    ).length > 0 &&
                                    this.props.GLOBAL_DATA.allPath.filter(p =>
                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                        p.groupeId === this.props.GLOBAL_DATA.selectedGroup.id &&
                                        (
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId).length > 0
                                        ))
                                        .map((p, index) => {
                                            return <span key={index}>
                                                {
                                                    this.props.GLOBAL_DATA.allPath.filter(p =>
                                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                        p.groupeId === this.props.GLOBAL_DATA.selectedGroup.id).length - 1 < index + 5 &&
                                                    <span>



                                                        <span className="PATH_ITEM" style={{ backgroundColor: "#" + p.typeColor }}
                                                            onClick={() => {
                                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId).length > 0 &&
                                                                    (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0])
                                                            }}>
                                                            <span className="TEXT_TICKET" style={{ display: "inline-block" }}>
                                                                {/* Indicateur de type de note */}
                                                                {
                                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                    IsTypeNoteIsAnCupBord(
                                                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].type === tn.id
                                                                        )[0].title) &&
                                                                    <span>
                                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11) }}></span>
                                                                        <span className="SPAN_SEP"></span>
                                                                    </span>
                                                                }

                                                                {
                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                    IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].title) &&
                                                                    parseInt(GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].title)) === p.noteId &&
                                                                    <span>
                                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_parent, 11) }}></span>
                                                                        <span className="SPAN_SEP"></span>
                                                                    </span>
                                                                }

                                                                {
                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                    IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].title) &&
                                                                    parseInt(GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].title)) !== p.noteId &&
                                                                    <span>
                                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 11) }}></span>
                                                                        <span className="SPAN_SEP"></span>
                                                                    </span>
                                                                }
                                                                {/* ==< */}


                                                                <span>{

                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                    (
                                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId).length > 0 &&
                                                                            IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].title) ?
                                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].title.split("]-")[1] :
                                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].title
                                                                    )

                                                                }</span>
                                                                <span className="SPAN_SEP"></span>




                                                                <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
                                                                    {
                                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId).length > 0 &&
                                                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                        (
                                                                            IsTypeNoteIsAnCupBord(
                                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].type === tn.id
                                                                                )[0].title) ?
                                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].type === tn.id
                                                                                )[0].title.replace(CupBoardCode, "") :
                                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0].type === tn.id
                                                                                )[0].title
                                                                        )
                                                                    }
                                                                </span>
                                                            </span>
                                                        </span>
                                                        &nbsp;
                                                        {
                                                            // this.props.GLOBAL_DATA.allPath.filter(p =>
                                                            //     this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            //     p.groupeId === this.props.GLOBAL_DATA.selectedGroup.id).length - 1 < index &&
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.arrowPath, 11, "EB6B16") }}></span>
                                                        }

                                                        &nbsp;
                                                        &nbsp;
                                                    </span>
                                                }

                                            </span>
                                        })
                                }

                                {
                                    this.props.GLOBAL_DATA.allPath.filter(p =>
                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                        p.groupeId === this.props.GLOBAL_DATA.selectedGroup.id).length === 0 &&
                                    <span>
                                        Aucune navigation trouvée dans le carnet, pour le moment ...
                                    </span>
                                }
                            </div>
                        }

                        {
                            this.state.selectedMenu !== undefined &&
                            (this.state.selectedMenu.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.pendingtasks &&
                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                            this.props.GLOBAL_DATA.DB_TASKS.concat([
                                {
                                    idtask: -1,
                                    title: "...",
                                    finished: false,
                                    idnote: -1,
                                    taskdate: (new Date()).getDate() + "/" + ((new Date()).getMonth() + 1) + "/" + (new Date()).getFullYear()
                                }
                            ])
                                .filter(t => !t.finished && t.taskdate !== null && t.taskdate.length > 0) &&
                            <div className="SYNTHESE_TIME_LINE_BLOCK">
                                {
                                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                    GetDistinctListOfString(this.props.GLOBAL_DATA.DB_TASKS
                                        .concat([
                                            {
                                                idtask: -1,
                                                title: "...",
                                                finished: false,
                                                idnote: -1,
                                                taskdate: (new Date()).getDate() + "/" + ((new Date()).getMonth() + 1) + "/" + (new Date()).getFullYear()
                                            }
                                        ])
                                        .filter(t =>
                                            // (
                                            //     this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                            //     this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote).length > 0
                                            // )
                                            // && 
                                            !t.finished && t.taskdate !== null && t.taskdate.length > 0)

                                        .sort((a, b) => { return GetDateObjectFromString(b.taskdate).dateObj.getTime() - GetDateObjectFromString(a.taskdate).dateObj.getTime() })
                                        .map(t => { return t.taskdate }))
                                        .map(td => {
                                            return <div key={td} className="SYNTESE_TIME_LINE_DATE_BOX" style={{
                                                backgroundColor:
                                                    ((new Date()).getFullYear() === parseInt(GetDateObjectFromString(td).year) &&
                                                        (new Date()).getMonth() + 1 === (parseInt(GetDateObjectFromString(td).month)) &&
                                                        (new Date()).getDate() === parseInt(GetDateObjectFromString(td).date)
                                                    ) ? "#fefdf1" : undefined
                                            }}>

                                                {/* {
                                                ((new Date()).getFullYear() <= parseInt(GetDateObjectFromString(td).year) &&
                                                    (new Date()).getMonth() <= (parseInt(GetDateObjectFromString(td).month) - 1) &&
                                                    (new Date()).getDate() <=parseInt(GetDateObjectFromString(td).date)
                                                )?"...":"!!!"
                                            } */}


                                                <div>
                                                    {
                                                        this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_TASKS
                                                            .filter(t => !t.finished && t.taskdate.length > 0 && t.taskdate === td).length > 0 &&
                                                        GetDateObjectFromString(td).date + " " +
                                                        (DB_MONTH_NAME[parseInt(GetDateObjectFromString(td).month) - 1]).substring(0, 3) + " " +
                                                        GetDateObjectFromString(td).year
                                                    }


                                                </div>
                                                {
                                                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_TASKS
                                                        .filter(t => !t.finished && t.taskdate.length > 0 && t.taskdate === td).length === 0 &&
                                                    <div className="TODAY_ZONE">
                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.calendar, 28, "EB6B16") }}></span>
                                                        <div style={{ fontSize: "22px" }}>
                                                            {
                                                                GetDateObjectFromString(td).date
                                                            }
                                                        </div>
                                                        <div style={{ fontSize: "22px" }}>
                                                            {
                                                                (DB_MONTH_NAME[parseInt(GetDateObjectFromString(td).month) - 1]).substring(0, 3)
                                                            }
                                                        </div>
                                                        <div style={{ fontSize: "11px", fontWeight: "bold" }}>
                                                            {
                                                                GetDateObjectFromString(td).year
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                {
                                                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_TASKS
                                                        .filter(t => !t.finished && t.taskdate.length > 0 && t.taskdate === td).length > 0 &&
                                                    <div className="SYNTHESE_TIME_LINE_DETAIL_BOX">


                                                        {
                                                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                            this.props.GLOBAL_DATA.DB_TASKS
                                                                .filter(t => !t.finished && t.taskdate.length > 0 && t.taskdate === td)
                                                                .map(t => {
                                                                    return <div key={t.idnote + "-" + t.idtask} onClick={() => {
                                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                                            (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0])
                                                                    }} className="SYNTHESE_TIME_LINE_ITEM" style={{
                                                                        backgroundColor:
                                                                            "#" + (this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === tn.id && n.idnote === t.idnote).length > 0
                                                                                ).length > 0 ?
                                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === tn.id && n.idnote === t.idnote).length > 0
                                                                                )[0].colorString : "ffffff")

                                                                    }}>
                                                                        <div className="TEXT_TICKET">
                                                                            <span style={{ fontWeight: "normal" }}>
                                                                                {
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                                                    (
                                                                                        IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title) ?
                                                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title.split("]-")[1] :
                                                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title
                                                                                    )
                                                                                }

                                                                                {
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length === 0 &&
                                                                                    <div>
                                                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.calendar, 16) }}></span>
                                                                                        <span className="SPAN_SEP"></span>
                                                                                        <span>Aujourd'hui</span>

                                                                                    </div>
                                                                                }

                                                                            </span>
                                                                            {
                                                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                                                <span className="SPAN_SEP"></span>
                                                                            }

                                                                            <span>{

                                                                                t.title.split("]:").length > 1 ?
                                                                                    t.title.split("]:")[1] :
                                                                                    t.title
                                                                            }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                })
                                                        }
                                                    </div>
                                                }


                                            </div>

                                        })
                                }


                            </div>
                        }

                        {
                            this.state.selectedMenu !== undefined &&
                            (this.state.selectedMenu.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.pendingtasks &&
                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                            this.props.GLOBAL_DATA.DB_TASKS.filter(t =>
                                // (
                                //     this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                //     this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.idnote === t.idnote).length > 0
                                // )
                                // && 
                                !t.finished && t.taskdate !== null && t.taskdate.length > 0).length === 0 &&
                            <span>Aucune tache à faire, pour le moment ...</span>
                        }

                        {
                            this.state.selectedMenu !== undefined &&
                            (this.state.selectedMenu.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.cupBoard &&
                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                (
                                    this.props.GLOBAL_DATA.selectedType !== undefined && this.props.GLOBAL_DATA.selectedType.length > 0 ?
                                        this.props.GLOBAL_DATA.selectedType.filter(stn => tn.id === stn).length > 0
                                        ||
                                        (
                                            // //Prise en compte également des classeurs dans la sélection
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === tn.id && n.associations !== null && n.associations.split(";").filter(a =>
                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                            ).length > 0).length > 0
                                        )
                                        : true
                                ) &&
                                IsTypeNoteIsAnCupBord(tn.title) &&
                                (
                                    (
                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === tn.id).length > 0
                                    ) ||
                                    (

                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === tn.id && n.associations !== null && n.associations.split(";").filter(a =>
                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                        ).length > 0).length > 0
                                    )
                                )
                            ).length > 0 &&
                            <div>
                                {/* Les derniers classeurs */}
                                <div className="NOTE_VIEW_RECEAP_WIGET_BOX" style={{ position: "relative", top: "0px" }}>
                                    <div className="NOTE_VIEW_RECAP_TITLE_BOX">
                                        <span>
                                            Organisation des résultats par classeurs
                                        </span>
                                    </div>
                                    {
                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                            (
                                                this.props.GLOBAL_DATA.selectedType !== undefined && this.props.GLOBAL_DATA.selectedType.length > 0 ?
                                                    this.props.GLOBAL_DATA.selectedType.filter(stn => tn.id === stn).length > 0
                                                    ||
                                                    (
                                                        // //Prise en compte également des classeurs dans la sélection
                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === tn.id && n.associations !== null && n.associations.split(";").filter(a =>
                                                            a.length > 0 &&
                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                                        ).length > 0).length > 0
                                                    )
                                                    : true
                                            ) &&
                                            IsTypeNoteIsAnCupBord(tn.title) &&
                                            (
                                                (
                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === tn.id).length > 0
                                                ) ||
                                                (

                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === tn.id && n.associations !== null && n.associations.split(";").filter(a =>
                                                        a.length > 0 &&
                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                                    ).length > 0).length > 0
                                                )
                                            )
                                        )
                                            // .slice(0, 3)
                                            .map(cupBoard => {
                                                return <div key={"cup" + cupBoard.id} className="VIEW_NOTE_SYNTHESE_CB_BOX">

                                                    <div className="">

                                                    </div>

                                                    {
                                                        <table>
                                                            <tbody>

                                                                <tr>
                                                                    <td style={{ textAlign: "center" }} title={"Cliquez pour ouvrir les classeurs de type '" +
                                                                        (
                                                                            IsCustForm(cupBoard.title) ?
                                                                                cupBoard.title.split("]-")[1] :
                                                                                cupBoard.title.split(CupBoardCode)[1]
                                                                        )
                                                                        + "'"} onClick={() => {
                                                                            (this.props.GLOBAL_FUNCTION.onSelectTypeNote as Function)(cupBoard.id)
                                                                        }}>
                                                                        <div dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 38, cupBoard.colorString as string) }}></div>
                                                                        <div>
                                                                            {
                                                                                IsCustForm(cupBoard.title) ?
                                                                                    cupBoard.title.split("]-")[1] :
                                                                                    cupBoard.title.split(CupBoardCode)[1]
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            (this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                this.props.GLOBAL_DATA.DB_NOTES
                                                                                    .filter(n => n.type === cupBoard.id &&
                                                                                        n.associations !== null && n.associations.split(";").filter(a =>
                                                                                            a.length > 0 &&
                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                                                                        ).length > 0)
                                                                                    .sort((a, b) => {
                                                                                        return b.associations !== null && a.associations !== null ?
                                                                                            b.associations.split(";").filter(_a =>
                                                                                                _a.length > 0 &&
                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(_a)).length > 0
                                                                                            ).length - a.associations.split(";").filter(_a =>
                                                                                                _a.length > 0 &&
                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(_a)).length > 0
                                                                                            ).length : a.idnote - b.idnote
                                                                                    }).length > 0) &&
                                                                            <div className="VIEW_NOTE_SYNTHESE_CB_DETAIL">
                                                                                {
                                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_NOTES
                                                                                        .filter(n => n.type === cupBoard.id &&
                                                                                            n.associations !== null && n.associations.split(";").filter(a =>
                                                                                                a.length > 0 &&
                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                                                                            ).length > 0)
                                                                                        .sort((a, b) => {
                                                                                            return b.associations !== null && a.associations !== null ?
                                                                                                b.associations.split(";").filter(_a =>
                                                                                                    _a.length > 0 &&
                                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(_a)).length > 0
                                                                                                ).length - a.associations.split(";").filter(_a =>
                                                                                                    _a.length > 0 &&
                                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(_a)).length > 0
                                                                                                ).length : a.idnote - b.idnote
                                                                                        })
                                                                                        .map(note => {
                                                                                            return <div key={note.idnote + "note"} className="NOTE_VIEW_SYNTHSE_CUBBOARD_ITEM">
                                                                                                <span title={"Cliquez pour ouvrir le classeur '" +
                                                                                                    (
                                                                                                        IsCustForm(note.title) ?
                                                                                                            note.title.split("]-")[1]
                                                                                                            : note.title
                                                                                                    )
                                                                                                    + "'"} onClick={() => {
                                                                                                        (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(note)
                                                                                                    }}>
                                                                                                    <span>
                                                                                                        <span dangerouslySetInnerHTML={{
                                                                                                            __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 12,
                                                                                                                cupBoard.colorString as string)
                                                                                                        }}></span>
                                                                                                    </span>
                                                                                                    <span className="SPAN_SEP"></span>
                                                                                                    <span>{
                                                                                                        IsCustForm(note.title) ?
                                                                                                            note.title.split("]-")[1]
                                                                                                            : note.title
                                                                                                    }</span>
                                                                                                </span>
                                                                                                <span>
                                                                                                    &nbsp;

                                                                                                    {
                                                                                                        <span className="NOTE_VIEW_SYNTHSE_MORE_INFO">
                                                                                                            <span className="NOTE_VIEW_SYNTHESE_ELEMENT_TITLE_BOX">
                                                                                                                <span>{
                                                                                                                    note.associations !== null && note.associations.split(";").filter(a =>
                                                                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                                                                                                    ).length
                                                                                                                }</span>
                                                                                                                <span className="SPAN_SEP"></span>
                                                                                                                <span dangerouslySetInnerHTML={{
                                                                                                                    __html: GetIconFromType(ENU_ICON_TYPE.note, 11)
                                                                                                                }}></span>
                                                                                                            </span>
                                                                                                            {/* Toutes les notes du classeur courant */}
                                                                                                            <div className="NOTE_VIEW_SYNTHSE_NOTES_LINKED_BOX">
                                                                                                                {
                                                                                                                    note.associations !== null && note.associations.split(";").filter(a =>
                                                                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                                                                                                    ).map(n => {
                                                                                                                        return <div key={"cupNote" + "-" + n} className="NOTE_VIEW_SYNTHSE_NOTES_LINKED_ITEM">
                                                                                                                            <div title={
                                                                                                                                "Cliquez pour ouvrir la note '" +
                                                                                                                                (
                                                                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                                                    (
                                                                                                                                        IsCustForm(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].title) ?
                                                                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].title.split("]-")[1] :
                                                                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].title
                                                                                                                                    )
                                                                                                                                )
                                                                                                                                + "'"
                                                                                                                            } onClick={() => {
                                                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                                                    (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0])
                                                                                                                            }}>
                                                                                                                                <span dangerouslySetInnerHTML={{
                                                                                                                                    __html: GetIconFromType(
                                                                                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                                                            IsCustForm(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].title) ?
                                                                                                                                            ENU_ICON_TYPE.note : ENU_ICON_TYPE.form_child, 11)
                                                                                                                                }}></span>
                                                                                                                                <span className="SPAN_SEP"></span>
                                                                                                                                <span>{
                                                                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                                                    (
                                                                                                                                        IsCustForm(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].title) ?
                                                                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].title.split("]-")[1] :
                                                                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].title
                                                                                                                                    )
                                                                                                                                }</span>
                                                                                                                                <span className="SPAN_SEP"></span>
                                                                                                                                <span className="SYNTHESE_DATE_ELEMENT">
                                                                                                                                    {
                                                                                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                                                                                        (
                                                                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].modified === this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].created ?
                                                                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].created :
                                                                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(n))[0].modified
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                {
                                                                                                                                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                                                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && t.idnote === parseInt(n)).map(t => {
                                                                                                                                        return <div key={"task-" + t.idtask}>
                                                                                                                                            <span dangerouslySetInnerHTML={{
                                                                                                                                                __html: GetIconFromType(ENU_ICON_TYPE.pendingtasks, 11,
                                                                                                                                                    t.taskdate !== null && t.taskdate.length > 0 ? "ff1c32" : "eb6b16"
                                                                                                                                                )
                                                                                                                                            }}>

                                                                                                                                            </span>
                                                                                                                                            <span className="SPAN_SEP"></span>
                                                                                                                                            <span>
                                                                                                                                                {
                                                                                                                                                    t.title
                                                                                                                                                }
                                                                                                                                            </span>
                                                                                                                                            {
                                                                                                                                                t.taskdate !== null && t.taskdate.length > 0 &&
                                                                                                                                                <span className="SYNTHESE_DATE_ELEMENT">
                                                                                                                                                    {
                                                                                                                                                        t.taskdate
                                                                                                                                                    }
                                                                                                                                                </span>
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    }

                                                                                                    &nbsp;
                                                                                                </span>
                                                                                            </div>
                                                                                        })
                                                                                }
                                                                            </div>
                                                                        }

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    }

                                                </div>
                                            })
                                    }


                                </div>
                            </div>
                        }

                        {
                            this.state.selectedMenu !== undefined &&
                            (this.state.selectedMenu.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.cupBoard &&
                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                (
                                    this.props.GLOBAL_DATA.selectedType !== undefined && this.props.GLOBAL_DATA.selectedType.length > 0 ?
                                        this.props.GLOBAL_DATA.selectedType.filter(stn => tn.id === stn).length > 0
                                        ||
                                        (
                                            // //Prise en compte également des classeurs dans la sélection
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === tn.id && n.associations !== null && n.associations.split(";").filter(a =>
                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                            ).length > 0).length > 0
                                        )
                                        : true
                                ) &&
                                IsTypeNoteIsAnCupBord(tn.title) &&
                                (
                                    (
                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === tn.id).length > 0
                                    ) ||
                                    (

                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.type === tn.id && n.associations !== null && n.associations.split(";").filter(a =>
                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.idnote === parseInt(a)).length > 0
                                        ).length > 0).length > 0
                                    )
                                )
                            ).length === 0 &&
                            <span>
                                Aucun classeur détecté, pour le moment ...
                            </span>
                        }

                        {
                            this.state.selectedMenu !== undefined &&
                            (this.state.selectedMenu.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.category &&
                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => !IsTypeNoteIsAnCupBord(tn.title) &&
                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === tn.id).length > 0).length > 0 &&
                            <div style={{ textAlign: "left", position: "relative", top: "0px" }}>
                                <div className="NOTE_VIEW_RECAP_TITLE_BOX">
                                    <span>
                                        Organisation des résultats par intercalaires
                                    </span>
                                </div>
                                {
                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => !IsTypeNoteIsAnCupBord(tn.title) &&
                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === tn.id).length > 0)
                                        .sort((a, b) => {
                                            return this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined ?
                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === b.id).length -
                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === a.id).length :
                                                a.id - b.id
                                        })
                                        // .slice(0, 3)
                                        .map(typeNote => {
                                            return <div key={typeNote.id + "-type"} className="NOTE_VIEW_SYNTHSE_TYPENOTE_ITEM">
                                                <span title={"Cliquez pour ouvrir l'intercalaire '" + typeNote.title + "'"} onClick={() => {
                                                    (this.props.GLOBAL_FUNCTION.onSelectTypeNote as Function)(typeNote.id)
                                                }}>
                                                    <span>
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: GetIconFromType(ENU_ICON_TYPE.category, 16,
                                                                typeNote.colorString as string)
                                                        }}></span>
                                                    </span>
                                                    <span className="SPAN_SEP"></span>
                                                    {
                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === typeNote.id &&
                                                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && t.idnote === fn.idnote).length > 0
                                                        ).length > 0 &&
                                                        <span>
                                                            <span>
                                                                <b>
                                                                    {
                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === typeNote.id &&
                                                                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && t.idnote === fn.idnote).length > 0
                                                                        ).length
                                                                    }
                                                                </b>
                                                            </span>
                                                            &nbsp;
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.pendingtasks, 11, "ff1c32") }}>

                                                            </span>

                                                            <span className="SPAN_SEP"></span>
                                                        </span>
                                                    }
                                                    <span>
                                                        <span>
                                                            <b>
                                                                {
                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === typeNote.id).length
                                                                }
                                                            </b>
                                                        </span>
                                                        &nbsp;
                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.note, 11) }}>

                                                        </span>
                                                        <span>{
                                                            typeNote.title
                                                        }</span>
                                                    </span>

                                                </span>

                                                {/* Liste des notes relatives */}
                                                <div className="VIEW_NOTE_SYNTHESE_CB_DETAIL" style={{ top: "28px" }}>
                                                    {
                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === typeNote.id)
                                                            .map(n => {
                                                                return <div key={n.idnote + "SYNTHESE"} className="NOTE_VIEW_SYNTHSE_NOTES_LINKED_ITEM" title={"Cliquez pour ouvrir la note '" +
                                                                    (
                                                                        IsCustForm(n.title) ?
                                                                            n.title.split("]-")[1] :
                                                                            n.title
                                                                    )
                                                                    + "'"} onClick={() => {
                                                                        (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(n)
                                                                    }}>
                                                                    <div>
                                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(IsCustForm(n.title) ? ENU_ICON_TYPE.note : ENU_ICON_TYPE.form_child, 11) }}>

                                                                        </span>
                                                                        <span className="SPAN_SEP"></span>
                                                                        {
                                                                            n.pined &&
                                                                            <span>
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.markBook, 11) }}>

                                                                                </span>
                                                                                <span className="SPAN_SEP"></span>
                                                                            </span>
                                                                        }


                                                                        <span>
                                                                            {
                                                                                IsCustForm(n.title) ?
                                                                                    n.title.split("]-")[1] :
                                                                                    n.title
                                                                            }
                                                                        </span>
                                                                        <span className="SYNTHESE_DATE_ELEMENT">
                                                                            {
                                                                                n.created !== n.modified ?
                                                                                    n.modified :
                                                                                    n.created
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && t.idnote === n.idnote).map(t => {
                                                                                return <div key={"O_TASK_" + t.idtask}>
                                                                                    <span dangerouslySetInnerHTML={{
                                                                                        __html: GetIconFromType(ENU_ICON_TYPE.pendingtasks, 11,
                                                                                            t.taskdate !== null && t.taskdate.length > 0 ? "ff1c32" : "eb6b16"
                                                                                        )
                                                                                    }}>

                                                                                    </span>
                                                                                    <span className="SPAN_SEP"></span>
                                                                                    <span>
                                                                                        {
                                                                                            t.title.indexOf("]:") > -1 && IsCustForm(n.title) ?
                                                                                                t.title.split("]:")[1] :
                                                                                                t.title
                                                                                        }
                                                                                    </span>
                                                                                    {
                                                                                        t.taskdate !== null && t.taskdate.length > 0 &&
                                                                                        <span className="SYNTHESE_DATE_ELEMENT">
                                                                                            {
                                                                                                t.taskdate
                                                                                            }
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            })
                                                    }
                                                </div>
                                            </div>
                                        })
                                }
                            </div>
                        }

                        {
                            this.state.selectedMenu !== undefined &&
                            (this.state.selectedMenu.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.category &&
                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => !IsTypeNoteIsAnCupBord(tn.title) &&
                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(fn => fn.type === tn.id).length > 0).length === 0 &&
                            <span>
                                Aucune intercalaire détecté, pour le moment ...
                            </span>
                        }
                    </div>
                </div>



                {
                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.taskdate.length > 0 && !t.finished &&
                        GetDateObjectFromString(t.taskdate).dateObj.getFullYear() === (new Date()).getFullYear() &&
                        GetDateObjectFromString(t.taskdate).dateObj.getMonth() === (new Date()).getMonth() &&
                        GetDateObjectFromString(t.taskdate).dateObj.getDate() === (new Date()).getDate()
                    ).length > 0 &&
                    !this.props.GLOBAL_DATA.loadingPendingData &&
                    //Affichage des taches du jour
                    //----------------------------
                    <div className="NOTE_VIEW_SYNTHESE_WIGET_BOX">
                        {/* Synthèse titre */}
                        <div style={{ fontWeight: "600", color: "#c1c1c1", textAlign: "left", fontSize: "32px", position: "relative", display: "block", paddingTop: "12px" }}>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.calendar, 24) }}></span>
                            <span className="SPAN_SEP"></span>
                            <span style={{ fontStyle: "italic", fontSize: "13px", fontWeight: "normal" }}>
                                A ne pas manquer ...
                            </span>
                            <div className="NOTE_VIEW_CURRENT_DATE_BOX">
                                <span className="NOTE_VIEW_CURRENT_DATE_BOX_PART">{
                                    (new Date()).getDate()
                                }</span>
                                &nbsp;
                                {
                                    DB_MONTH_NAME[(new Date()).getMonth()]
                                }
                                &nbsp;
                                {
                                    (new Date()).getFullYear()
                                }
                            </div>
                        </div>

                        {
                            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.taskdate.length > 0 && !t.finished &&
                                GetDateObjectFromString(t.taskdate).dateObj.getFullYear() === (new Date()).getFullYear() &&
                                GetDateObjectFromString(t.taskdate).dateObj.getMonth() === (new Date()).getMonth() &&
                                GetDateObjectFromString(t.taskdate).dateObj.getDate() === (new Date()).getDate()
                            ).map(t => {
                                return <div key={"_o_Ttask" + t.idtask} className="NOTE_VIEW_SYNTHESE_NOTE_ITEM_BOX" style={{
                                    display: "inline-block", marginRight: "14px", padding: "2px", width: "auto",
                                    backgroundColor: this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn =>
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                            _tn.id === this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].type).length > 0 ? "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                _tn.id === this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].type)[0].colorString : undefined
                                }} onClick={() => {
                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                        (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0])
                                }}>
                                    <span className="TEXT_TICKET">
                                        <div >
                                            {
                                                //Si formulaire parent
                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title) &&
                                                parseInt(GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title)) === t.idnote &&
                                                <span>
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_parent, 11) }}></span>
                                                    <span className="SPAN_SEP"></span>
                                                </span>
                                            }

                                            {
                                                //Si formulaire enfant
                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title) &&
                                                parseInt(GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title)) !== t.idnote &&
                                                <span>
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 11) }}></span>
                                                    <span className="SPAN_SEP"></span>
                                                </span>
                                            }

                                            {
                                                //Si classeur
                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.props.GLOBAL_DATA.DB_NOTES !== undefined && tn.id === this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].type).length > 0 &&
                                                IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.props.GLOBAL_DATA.DB_NOTES !== undefined && tn.id === this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].type)[0].title) &&
                                                <span>
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11) }}></span>
                                                    <span className="SPAN_SEP"></span>
                                                </span>
                                            }


                                            <span>{
                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote).length > 0 &&
                                                (IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title) ?
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title
                                                        .split(NOTE_CUST_FORM_EMPTY_TITLE + (NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", GetNoteCustParentIdFromTitle(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title))))[1] :
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === t.idnote)[0].title)
                                            }</span>
                                        </div>
                                        <div>
                                            <span>{
                                                t.title.indexOf("]:") > -1 ? t.title.split("]:")[1] : t.title
                                            }</span>
                                            <span className="SPAN_SEP"></span>
                                            <span>{t.taskdate}</span>
                                        </div>
                                    </span>
                                </div>
                            })
                        }
                    </div>
                }


                {/* Synthèse titre */}
                {
                    this.state.showAllSynthese &&
                    <div style={{ fontWeight: "600", color: "#c1c1c1", textAlign: "left", fontSize: "32px", position: "relative", display: "block", paddingLeft: "42px", marginTop: "43px" }}>
                        <span>{
                            "SYNTHESE"
                        }</span>
                        <span className="SPAN_SEP"></span>
                        <span style={{ fontStyle: "italic", fontSize: "13px", fontWeight: "normal" }}>
                            Notes et catégories courantes
                        </span>
                    </div>
                }


                {
                    this.state.showAllSynthese &&
                    <div style={{ marginBottom: "28px", borderBottom: "1px dashed #e1e1e1", paddingBottom: "14px" }}>
                        <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                            this.showAllSynthse();
                        }}>
                            Masquer le détail de la synthèse
                        </span>
                    </div>
                }

                {
                    this.state.showAllSynthese &&
                    <div className="NOTE_VIEW_SYNTHSE_BOX_outer-block" style={{
                        columnCount:
                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES! !== undefined &&
                                GetDistinctListOfString(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.map(n => { return n.type.toString() })).length < 4 ?
                                GetDistinctListOfString(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.map(n => { return n.type.toString() })).length : "4"
                    }} >

                        {
                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                            GetDistinctListOfString(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.map(n => { return n.type.toString() })).map(tn => {
                                return <div key={tn} className="NOTE_VIEW_SYNTHSE_BOX_ITEM" style={{
                                    backgroundColor: this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn)).length > 0 ? "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].colorString : undefined
                                }} id={"SYNTHESE_" + tn}>
                                    <div className="TEXT_TICKET" style={{ display: "block", marginBottom: "1px" }} >
                                        <span style={{ display: "block", textAlign: "left", }}>
                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.coofee, 18, "e1e1e1") }}>
                                            </span>
                                        </span>

                                        <span style={{ fontSize: "18px", position: "relative", top: "-14px" }}>
                                            {
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn)).length > 0 &&
                                                IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title) &&
                                                <span>
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11) }}>

                                                    </span>
                                                    <span className="SPAN_SEP">
                                                    </span>
                                                </span>
                                            }

                                            {
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn)).length > 0 &&
                                                !IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title) &&
                                                <span>
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.category, 11) }}>

                                                    </span>
                                                    <span className="SPAN_SEP">
                                                    </span>
                                                </span>
                                            }



                                            <span>{
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn)).length > 0 &&
                                                (IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title) ?
                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title.replace(CupBoardCode, "")
                                                    : this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title)
                                            }</span>
                                        </span>
                                    </div>

                                    <div style={{ background: "linear-gradient(-90deg, #ffffff, transparent)", width: "100%" }}>

                                        {
                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.filter(n => n.type === parseInt(tn)).map(n => {
                                                return <div key={"o_note" + n.idnote} onClick={() => {



                                                    //Conditionnement de l'accès à la note
                                                    //Règles : si la note est présente dans 
                                                    //les notes filtrées affichées alors Goto, 
                                                    //sinon chargement de la mémoire locale
                                                    //------------------------------------
                                                    // this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                    //     this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                    //     this.props.GLOBAL_DATA.DB_FILTERED_NOTES
                                                    //         .sort((a, b) => { return this.props.GLOBAL_DATA.filterByLastChange !== undefined && this.props.GLOBAL_DATA.filterByLastChange ? GetDateObjectFromString(b.modified).dateObj.getTime() - GetDateObjectFromString(a.modified).dateObj.getTime() : GetDateObjectFromString(b.created).dateObj.getTime() - GetDateObjectFromString(a.created).dateObj.getTime() })
                                                    //         .slice(0,
                                                    //             this.props.GLOBAL_DATA.memoryCacheSize).filter(_n => _n.idnote === n.idnote &&
                                                    //                 (
                                                    //                     this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                    //                     this.props.GLOBAL_DATA.DB_NOTES.filter(__n => n.associations !== null && n.associations.split(";").filter(a => parseInt(a) === __n.idnote)).length > 0
                                                    //                 )
                                                    //             ).length > 0 ?
                                                    //     //Si la note est enfant, ouverture du noeud parent
                                                    //     //------------------------------------------------
                                                    //     GoToElement("NOTE_ITEM_" + n.idnote)
                                                    //     :
                                                    //     (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(n)

                                                    (this.props.GLOBAL_FUNCTION.onPreviewNote as Function)(n);


                                                }} className="NOTE_VIEW_SYNTHESE_NOTE_ITEM_BOX" >

                                                    <div className="TEXT_TICKET" style={{ display: "block", minHeight: "55px" }}>
                                                        <div >

                                                            <div className="TEXT_TICKET" style={{
                                                                display: "inline-block", backgroundColor: this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined && this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn)).length > 0 ?
                                                                    "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].colorString : "initial",
                                                                top: "0px", paddingLeft: "2px", paddingRight: "2px", paddingBottom: "1px", marginRight: "7px", left: "-6px"
                                                            }}>
                                                                <div className="TEXT_TICKET">
                                                                    <span style={{ fontSize: "11px", position: "relative" }}>
                                                                        {
                                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn)).length > 0 &&
                                                                            IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title) &&
                                                                            <span>
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11, this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].colorString) }}>

                                                                                </span>
                                                                                <span className="SPAN_SEP">
                                                                                </span>
                                                                            </span>
                                                                        }

                                                                        {
                                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn)).length > 0 &&
                                                                            !IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title) &&
                                                                            <span>
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.category, 11, this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].colorString) }}>

                                                                                </span>
                                                                                <span className="SPAN_SEP">
                                                                                </span>
                                                                            </span>
                                                                        }




                                                                        <span >{
                                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn)).length > 0 &&
                                                                            (IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title) ?
                                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title.replace(CupBoardCode, "")
                                                                                : this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(_tn => _tn.id === parseInt(tn))[0].title)
                                                                        }</span>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            {
                                                                //Indicateur de présence d'une nouvelle note (délais 3jrs)
                                                                //--------------------------------------------
                                                                (GetDateObjectFromString(n.created).dateObj.setDate(GetDateObjectFromString(n.created).dateObj.getDate() + 3)) >
                                                                (new Date()).getTime() &&
                                                                <span style={{ display: "block", textAlign: "right" }}>
                                                                    <span className="APP_NOTE_NEW_ITEM_INDICATOR"
                                                                        style={{ display: "block", textAlign: "right", top: "0px", position: "absolute", right: "auto", left: "-24px" }}
                                                                        dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.newElement, 18, "1a7411") }}></span>
                                                                </span>
                                                            }


                                                            {
                                                                IsCustForm(n.title) &&
                                                                parseInt(GetNoteCustParentIdFromTitle(n.title)) === n.idnote &&
                                                                <span>
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_parent, 11) }}></span>
                                                                    <span className="SPAN_SEP"></span>
                                                                </span>
                                                            }

                                                            {
                                                                IsCustForm(n.title) &&
                                                                parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote &&
                                                                <span>
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 11) }}></span>
                                                                    <span className="SPAN_SEP"></span>
                                                                </span>
                                                            }
                                                            <span style={{ fontSize: "14px", fontWeight: "600" }}>{
                                                                IsCustForm(n.title) ? n.title.split("]-")[1] : n.title
                                                            }</span>

                                                            {
                                                                (this.props.GLOBAL_DATA.allNotifs.filter(notif =>
                                                                    notif.typeId === n.type && notif.noteId === n.idnote
                                                                ).length > 0) &&
                                                                <span>
                                                                    <span className="SPAN_SEP" ></span>
                                                                    <span className="TN_MENU_SELECTED_INDICATOR" style={{ position: "relative", display: "inline-block", height: "16px", width: "16px", top: "0px", left: "0px" }}>
                                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.notif, 14, "ffffff") }}>

                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            }

                                                            <span className="NOTE_SYNTHESE_DATE_ZONE">
                                                                {
                                                                    n.created !== n.modified ? n.modified : n.created
                                                                }

                                                                {
                                                                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === n.idnote && !t.finished && t.taskdate.length > 0).length > 0 &&
                                                                    <div className="APP_ITEM_TASK_PROGRESS_TIME">
                                                                        {
                                                                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === n.idnote && !t.finished && t.taskdate.length > 0).map(t => {
                                                                                return <div key={"comput_" + t.idtask} className="APP_ITEM_TASK_PROGRESS_TIME_VALUE" style={{
                                                                                    width:
                                                                                        (100 - (((((GetDateObjectFromString(t.taskdate).dateObj.getTime()) * 100) / (new Date()).getTime()) - 100) * 1000)) + "%",
                                                                                    backgroundColor: "#" +
                                                                                        ((100 - (((((GetDateObjectFromString(t.taskdate).dateObj.getTime()) * 100) / (new Date()).getTime()) - 100) * 1000)) < 50 ? "39db43" :
                                                                                            ((100 - (((((GetDateObjectFromString(t.taskdate).dateObj.getTime()) * 100) / (new Date()).getTime()) - 100) * 1000)) < 70 ? "ffa32b" :
                                                                                                "bc0000"))
                                                                                }}>

                                                                                </div>
                                                                            })
                                                                        }



                                                                    </div>
                                                                }
                                                            </span>





                                                            <span className="NOTE_VIEW_SYNTHESE_NOTE_INDICATOR_BOX">
                                                                {
                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES !== undefined &&
                                                                    <span
                                                                        className="NOTE_SYNTHESE_INDICATOR_INDEX"
                                                                        style={{
                                                                            backgroundColor: this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) < 5 ? "#00ff04" :
                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) < 10 ? "#6ff271" :
                                                                                    this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) < 15 ? "#ffbb00" :
                                                                                        this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) < 20 ? "#f9d36b" :
                                                                                            this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) < 25 ? "#bfbfbf" :
                                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) < 30 ? "#f4f4f4" : "#e1e1e1",
                                                                            color: this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) < 5 ? "#3c3c3c" :
                                                                                this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) < 10 ? "#3c3c3c" : undefined
                                                                        }}
                                                                    >{(this.props.GLOBAL_DATA.DB_FILTERED_NOTES.indexOf(n) + 1)}</span>
                                                                }

                                                                {
                                                                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && n.idnote === t.idnote).length > 0 &&
                                                                    <span title={this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && n.idnote === t.idnote).map(_t => { return " | " + _t.title + " - " + (_t.taskdate.length > 0 ? _t.taskdate : "") }).toString()} dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.pendingtasks, 14, "ff0000") }}></span>
                                                                }

                                                                {
                                                                    n.pined &&
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.markBook, 11) }}></span>
                                                                }

                                                            </span>


                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>

                            })
                        }
                    </div>
                }

                {
                    !this.state.showAllSynthese &&
                    <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                        this.showAllSynthse();
                    }}>
                        Afficher le détail la synthèse
                    </span>
                }

            </div>
        } catch (err) {
            return <div>
                {
                    (this.props.GLOBAL_FUNCTION.showMessage as Function)({
                        allMSG: [
                            {
                                key: "Oups !!! une erreur est survenue ....",
                                value: "Désolé, il semble qu'une erreur de chargement est survenue. Merci de cliquer sur le bouton 'Continuer' pour rechercher le composant.'",
                                data: ENU_ICON_TYPE.error
                            }
                        ],
                        showCloseButton: false,
                        buttons: [
                            {
                                title: "Continuer",
                                F_BUTTON_ACTION: () => {



                                    (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                }
                            }
                        ]
                    } as I_MSG_OPTION)
                }
            </div>
        }


    }
}