import * as React from "react";
import { I_FIELD_TEXT_PROPS, I_FIELD_TEXT_PROPS_STATE } from "../../02_Interfaces/IFIELD";
import { ENU_ICON_TYPE, I_KEYBOARD_FUNCTION } from "../../02_Interfaces/IGLOBAL";
import { GetIconFromType } from "../../04_TOOLS/tools";
import "../../99_Styles/field.css";

export class FIELD_TEXT extends React.Component<I_FIELD_TEXT_PROPS, I_FIELD_TEXT_PROPS_STATE> {

    constructor(props: I_FIELD_TEXT_PROPS) {
        super(props);
        this.state = {
            value: this.props.value,
            fieldIsOk: true
        }

        this.onChangeFieldValue = this.onChangeFieldValue.bind(this);
        this.onCheckFieldStatus = this.onCheckFieldStatus.bind(this);
        this.onForceFieldValue = this.onForceFieldValue.bind(this);
        this.onSelectProposal = this.onSelectProposal.bind(this);
    }

    onSelectProposal(proposal: string) {
        this.setState({ selectedProposal: proposal, value: proposal });
    }

    onChangeFieldValue(e: React.FormEvent) {
        if (
            (this.props.maxLength !== undefined && this.state.value.length <= this.props.maxLength) ||
            (this.props.maxLength !== undefined && this.state.value.length > (e.target as HTMLInputElement).value.length) ||
            (this.props.maxLength === undefined)
        ) {
            this.setState({
                value:

                    this.props.numberOnly !== undefined && this.props.numberOnly ?
                        (
                            (
                                (((e.target as HTMLInputElement).value.replace(",", ".").indexOf(".") > -1)
                                    && (e.target as HTMLInputElement).value.replace(",", ".").split(".").length < 3 && (e.target as HTMLInputElement).value.replace(",", ".").split(".")[1].length === 0)
                            ) ?
                                (e.target as HTMLInputElement).value.replace(",", ".") :
                                parseFloat((e.target as HTMLInputElement).value).toString() === "NaN" ? "0" : parseFloat((e.target as HTMLInputElement).value).toString()
                        ) :
                        this.props.onlyUperCase !== undefined &&
                            this.props.onlyUperCase ?
                            (e.target as HTMLInputElement).value.toUpperCase():
                            (e.target as HTMLInputElement).value
            }, () => {
                this.onCheckFieldStatus();
                if (this.props.F_Action !== undefined) {
                    (this.props.F_Action as Function)(this.state.value);
                }
            })
        }

    }

    onValidateByKeyBoard(e: React.KeyboardEvent) {
        if (e.which === (parseInt((this.props.keyAction as I_KEYBOARD_FUNCTION).keyboardValue)) && this.props.keyAction !== undefined) {
            (this.props.keyAction as I_KEYBOARD_FUNCTION).F_Action(this.state.value);
        }
    }

    onForceFieldValue(value: string) {
        this.setState({ value: value });
    }

    onCheckFieldStatus() {
        if (this.state.value !== undefined)
            if (this.props.required && this.state.value.length === 0) {
                if (this.state.fieldIsOk)
                    this.setState({ fieldIsOk: false });
            } else {
                if (!this.state.fieldIsOk)
                    this.setState({ fieldIsOk: true })
            }
    }

    getCurrentFieldStatus() {
        this.onCheckFieldStatus();

        if (this.props.required && this.state.value.length === 0) {
            return false;
        } else {
            return true;
        }
    }


    componentDidUpdate() {
        this.onCheckFieldStatus();
    }


    render(): JSX.Element {
        return <div className="FIELD_ZONE" >
            {
                this.props.title !== "***" &&
                <span className={this.props.required ? "FIELD_LABEL FIELD_LABEL_REQUIRED" : "FIELD_LABEL"}>
                    {this.props.title}
                </span>
            }


            {
                (this.props.asDisplayMode === undefined || (this.props.asDisplayMode !== undefined &&
                    !this.props.asDisplayMode)) &&
                <span className="FIELD_INPUT" style={{ width: "80%", display: "block", position: "relative" }}>
                    <span>
                        <span>
                            {
                                <input
                                    disabled={this.props.disable}
                                    value={this.state.value}
                                    type={
                                        (this.props.asPassword !== undefined && (this.props.asPassword as boolean)) ?
                                            "password" :
                                            ((this.props.asEmail !== undefined && (this.props.asEmail as boolean)) ?
                                                "email" : "text")
                                    } onChange={(e) => { this.onChangeFieldValue(e) }}
                                    placeholder={this.props.placeholder !== undefined ? this.props.placeholder as string : ""}
                                    className={this.state.fieldIsOk ? "FIELD_INPUT_COMPONENT" : "FIELD_INPUT_COMPONENT FIELD_INPUT_COMPONENT_ONERROR"}
                                    onKeyPress={this.props.keyAction !== undefined ? (e) => {
                                        this.onValidateByKeyBoard(e)
                                    } : () => { }
                                    }
                                />
                            }
                        </span>
                        {
                            (this.props.asPassword !== undefined && this.props.asPassword) &&
                            <span className="FIELD_AS_PASS" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.key, 16) }}>{ }

                            </span>
                        }
                    </span>


                    {
                        this.props.proposal !== undefined &&
                        <div>
                            <div className="FIELD_SUB_LABEL">Proposition(s)</div>
                            {
                                this.props.proposal
                                .filter(p=>p.length>0)
                                .map(p => {
                                    return <span onClick={() => {
                                        this.onSelectProposal(p);
                                    }} className={
                                        ((this.state.selectedProposal !== undefined && this.state.selectedProposal === p) || this.state.value === p) ? "FIELD_PROPOSAL_ITEM_BOX FIELD_PROPOSAL_ITEM_BOX_SELECTED" : "FIELD_PROPOSAL_ITEM_BOX"}>
                                        {
                                            p
                                        }
                                    </span>
                                })

                            }
                        </div>

                    }

                </span>
            }

            {
                ((this.props.asDisplayMode !== undefined &&
                    this.props.asDisplayMode)) &&
                <span className="FIELD_INPUT FIELD_DISPLAY_MODE">
                    {
                        this.state.value
                    }
                </span>
            }

        </div>
    }
}